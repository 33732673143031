import React, { Suspense } from "react";
import LoadingDialogBox from "../common/dialogBox/LoadingDialogBox";

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingDialogBox open={true} />}>
      <Component {...props} />
    </Suspense>
  );

export default Loadable;

export function MyLoadable(Component) {
  return function ReturnComponent(props) {
    return (
      <Suspense fallback={<LoadingDialogBox open={true} />}>
        <Component {...props} />
      </Suspense>
    );
  };
}
