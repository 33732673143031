/* eslint-disable import/no-anonymous-default-export */
import { lazy } from "react";
import Loadable from "../../components/layout/Loadable";

const Blog = Loadable(lazy(() => import("../../pages/admin/blogs")));
const AddBlog = Loadable(lazy(() => import("../../pages/admin/blogs/AddBlog")));
const EditBlog = Loadable(lazy(() => import("../../pages/admin/blogs/EditBlog")));
const ReorderBlogs = Loadable(lazy(() => import("../../pages/admin/blogs/ReorderBlogs")));


const News = Loadable(lazy(() => import("../../pages/admin/news")));
const AddNews = Loadable(lazy(() => import("../../pages/admin/news/AddNews")));
const EditNews = Loadable(lazy(() => import("../../pages/admin/news/EditNews")));
const ReorderNews = Loadable(lazy(() => import("../../pages/admin/news/ReorderNews")));


const Enquiries = Loadable(lazy(() => import("../../pages/admin/enquiries")));

const Documents = Loadable(lazy(() => import("../../pages/admin/documents")));
const AddDocuments =  Loadable(lazy(() => import("../../pages/admin/documents/AddDocument")));
const EditDocuments =  Loadable(lazy(() => import("../../pages/admin/documents/EditDocument")));


const Meta = Loadable(lazy(() => import("../../pages/admin/meta")));
const AddMeta = Loadable(lazy(() => import("../../pages/admin/meta/AddMeta")));
const EditMeta = Loadable(
  lazy(() => import("../../pages/admin/meta/EditMeta"))
);

export default [
  {
    path: "blogs",
    children: [
      {
        path: "",
        element: <Blog />,
      },
      {
        path: "reorder",
        element: <ReorderBlogs />,
      },
      {
        path: "add",
        element: <AddBlog />,
      },
      {
        path: "edit/:BlogID",
        element: <EditBlog />,
      },
    ],
  },
  {
    path: "meta",
    children: [
      {
        path: "",
        element: <Meta />,
      },

      {
        path: "add",
        element: <AddMeta />,
      },
      {
        path: "edit/:ID",
        element: <EditMeta />,
      },
    ],
  },
  {
    path: "news",
    children: [
      {
        path: "",
        element: <News />,
      },
      {
        path: "reorder",
        element: <ReorderNews />,
      },
      {
        path: "add",
        element: <AddNews />,
      },
      {
        path: "edit/:NewsID",
        element: <EditNews />,
      },
    ],
  },
  {
    path: "enquiries",
    element: <Enquiries />,
  },
  {
    path: "documents",
    children: [
      {
        path: "",
        element: <Documents />,
      },
      {
        path: "add",
        element: <AddDocuments />,
      },
      { 
        path: "edit/:DocumentID",
        element: <EditDocuments />,
      },
    ],
  },
]
