import React from "react";
import AppRouter from "./routers/AppRouter";
import { ThemeProvider } from "@mui/material/styles";
import { styled, useTheme } from "@mui/material";
import { theme as ui } from "./theme";
import { CssBaseline } from "@mui/material";
import { MaterialDesignContent, SnackbarProvider } from "notistack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import LoadingDialogBox from "./components/common/dialogBox/LoadingDialogBox";
import { useSelector } from "react-redux";

export default function App() {
  const theme = useTheme();
  const { PageLoading } = useSelector((state) => state.app);

  const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
    "&.notistack-MuiContent-success": {
      backgroundColor: "#afe6e3",
      color: theme.palette.text.secondary,
      border: "1px solid",
      borderColor: theme.palette.success.light,
      boxShadow: "none",
      width: "100%",
    },
    "&.notistack-MuiContent-error": {
      backgroundColor: "#FDEDE8",
      color: theme.palette.text.secondary,
      border: "1px solid",
      borderColor: theme.palette.error.light,
      boxShadow: "none",
      width: "100%",
    },
  }));

  return (
    <ThemeProvider theme={ui}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <CssBaseline />
        <div>
          <SnackbarProvider
            maxSnack={4}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            Components={{
              success: StyledMaterialDesignContent,
              error: StyledMaterialDesignContent,
            }}
            // autoHideDuration={4000}
          >
            <AppRouter />
          </SnackbarProvider>
          <LoadingDialogBox open={PageLoading} />
        </div>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
