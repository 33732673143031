import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  PageLocation: null,
  ActivePageNumber: null,
  PageLoading: false,
};

export const AppSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setPageLocation: (state, action) => {
      state.PageLocation = action.payload.PageLocation;
      state.ActivePageNumber = action.payload.ActivePageNumber;
    },
    resetPageLocation: (state, action) => {
      state.PageLocation = null;
      state.ActivePageNumber = null;
    },
    setPageLoading: (state, action) => {
      state.PageLoading = action.payload || false;
    },
  },
});

export const { setPageLocation, resetPageLocation, setPageLoading } =
  AppSlice.actions;

export default AppSlice.reducer;
